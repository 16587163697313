import { template as template_a228b02dd9a641279a04ac37a22f3d7a } from "@ember/template-compiler";
const WelcomeHeader = template_a228b02dd9a641279a04ac37a22f3d7a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
